import { $fetch, FetchOptions, FetchError } from 'ofetch'

const CSRF_COOKIE = 'XSRF-TOKEN'
const CSRF_HEADER = 'X-XSRF-TOKEN'

// could not import these types from ofetch, so copied them here
interface ResponseMap {
  blob: Blob
  text: string
  arrayBuffer: ArrayBuffer
}

type ResponseType = keyof ResponseMap | 'json'
// end of copied types

export type LarafetchOptions<R extends ResponseType> = FetchOptions<R> & {
  redirectIfNotAuthenticated?: boolean
  redirectIfNotVerified?: boolean
  isBackend?: boolean
  addApiToken?: boolean
}

export async function $larafetch<T, R extends ResponseType = 'json'>(
  path: RequestInfo,
  {
    redirectIfNotAuthenticated = true,
    redirectIfNotVerified = true,
    isBackend = true,
    addApiToken = false,
    ...options
  }: LarafetchOptions<R> = {}
) {
  // const {backendUrl, frontendUrl} = useRuntimeConfig().public;
  const { backendUrl, proxyUrl } = useRuntimeConfig().public
  const router = useRouter()

  let token = localStorage.getItem('token')
  // on client initiate a csrf request and get it from the cookie set by laravel
  // if (
  //   process.client &&
  //   ['post', 'delete', 'put', 'patch'].includes(
  //     options?.method?.toLowerCase() ?? ''
  //   )
  // ) {
    // await initCsrf()
    // cannot use nuxt composables such as useCookie after an async operation:
    // https://github.com/nuxt/framework/issues/5238
    // token =localStorage.getItem('token')
  // }

  let headers: any = {
    accept: 'application/json',
    host: backendUrl.replace(/https?:\/\//, '').split('/')[0],
    ...options?.headers,
    // ...(token && { [CSRF_HEADER]: token }),
    Authorization: addApiToken ? `Bearer ${token}` : undefined,
  }

  if (process.server) {
    headers = {
      ...headers,
      ...useRequestHeaders(['cookie']),
      // referer: frontendUrl,
    }
  }

  try {
    return await $fetch<T, R>(path, {
      baseURL: isBackend ? proxyUrl : '',
      ...options,
      headers,
      credentials: 'include',
    })
  } catch (error) {
    if (!(error instanceof FetchError)) throw error

    // when any of the following redirects occur and the final throw is not caught then nuxt SSR will log the following error:
    // [unhandledRejection] Error [ERR_HTTP_HEADERS_SENT]: Cannot set headers after they are sent to the client

    const status = error.response?.status ?? -1

    if (redirectIfNotAuthenticated && [401, 419].includes(status)) {
      const currentPath = router.currentRoute.value.fullPath

      if (currentPath !== '/signup' && 
        currentPath !== '/forget-password' && 
        !currentPath.includes('/password-reset')) {
        currentPath !== '/password-reset' && 
        await router.push('/login')
      }
    }

    if (redirectIfNotVerified && [409].includes(status)) {
      await router.push('/verify-email')
    }

    if ([500].includes(status)) {
      console.error('[Laravel Error]', error.data?.message, error.data)
    }

    throw error
  }
}

async function initCsrf() {
  // const {backendUrl} = useRuntimeConfig().public;
  // await $fetch("/api/proxy/sanctum/csrf-cookie", {
  //     baseURL: backendUrl,
  //     credentials: "include",
  // });
}

// https://github.com/axios/axios/blob/bdf493cf8b84eb3e3440e72d5725ba0f138e0451/lib/helpers/cookies.js
function getCookie(name: string) {
  const match = document.cookie.match(
    new RegExp('(^|;\\s*)(' + name + ')=([^;]*)')
  )
  return match ? decodeURIComponent(match[3]) : null
}
