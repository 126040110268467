import {storeToRefs} from 'pinia'
import {useAuthStore} from '~/stores/auth'
import {toInteger} from "lodash-es";

export default function useAuth() {
  const router = useRouter()

  const {user} = storeToRefs(useAuthStore())
  const isLoggedIn = computed(() => !!user.value)
  const token = computed<string | null>({
    get: () => {
      const refreshTime = localStorage.getItem('refresh_token')
      // console.log('refresh time: ', refreshTime, Date.now())
      if (toInteger(refreshTime) < Date.now()) {
        refreshToken().then(() => {})
      }
      let res =  localStorage.getItem('token')
      if (!res) {
        logout()
      }

      return res
    },
    set: (value: any) => {
      if (!value) {
        localStorage.removeItem('token')
        return
      }
      localStorage.setItem('token', value)
      localStorage.setItem('refresh_token', (Date.now() + (1000 * 60 * 10)).toString())
      // localStorage.setItem('refresh_token', (Date.now()).toString())
    }
  })

  async function refreshToken() {
    await $larafetch<{
      access_token: string
      token_type: string
      expires_in: string
    }>('/api/v1/token/refresh', {
      method: 'POST',
      addApiToken: true,
    }).then((res) => {
      token.value = res.access_token
    }).catch((error) => {
      logout()
    })
  }

  // const login = async (credentials: {
  //   name?: string
  //   email: string
  //   password: string
  //   remember?: false
  //   password_confirmation?: string
  // }) => {
  //   const res = await $larafetch<{
  //     access_token: string
  //     token_type: string
  //     expires_in: string
  //   }>('api/v1/token/login', {
  //     method: 'POST',
  //     headers: {'Content-Type': 'application/json'},
  //     body: JSON.stringify(credentials),
  //   })
  //
  //   if (!res.access_token) {
  //     throw new Error('Failed to login')
  //   }
  //
  //   console.log('login')
  //   token.value = res.access_token
  // }

  async function register(credentials: {
    name?: string
    email: string
    password: string
    remember?: false
    password_confirmation?: string
  }) {
    await $larafetch('/register', {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify(credentials),
    })

    await login(credentials)
  }

  function logout() {
    user.value = null
    token.value = null

    localStorage.removeItem('token')
    $fetch('/api/logout', {method: 'POST'})
      .then(() => {
        // Redirect to the login page after successful logout
        const currentPath = router.currentRoute.value.fullPath

        if (currentPath !== '/signup' &&
          currentPath !== '/forget-password' &&
          !currentPath.includes('/password-reset')) {
          currentPath !== '/password-reset' &&
          navigateTo('/login', {replace: true})
        }
      })
      .catch((error) => {
        console.error('Error logging out:', error)
        // Handle the error
      })
  }

  // async function refreshTokenAndRetry(error: any) {
  //   try {
  //     const { access_token } = await $larafetch<{
  //       access_token: string
  //       token_type: string
  //       expires_in: string
  //     }>('/v1/token/refresh', {
  //       method: 'POST',
  //       headers: { 'Content-Type': 'application/json' },
  //     })
  //     token.value = access_token
  //     await refresh()
  //   } catch (e) {
  //     return Promise.reject(error)
  //   }
  // }

  return {
    register,
    user,
    isLoggedIn,
    token,
    // login,
    logout,
  }
}
